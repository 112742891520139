import React from 'react';

import * as S from './styled';

type ILayoutProps = {
    children: React.ReactNode;
};

const Layout = ({ children }: ILayoutProps): JSX.Element => <S.Layout>{children}</S.Layout>;

export default Layout;
