import { COLORS } from '@constants/colors';
import styled from 'styled-components';
import { SCREENS } from '@constants/screens';

// Large Item

export const LargeItem = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ImageContainer = styled.div`
    width: 255px;
    margin-left: 55px;

    ${SCREENS.desktopAverageAndUpper} {
        width: 288px;
        margin-left: 104px;
    }

    ${SCREENS.desktopLargeAndUpper} {
        width: 490px;
        margin-left: 48px;
    }
`;

export const ItemTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;
`;

export const ItemTitle = styled.span`
    font-size: 18px;
    font-weight: 800;
    color: ${COLORS.blueDarker};
`;

export const Text = styled.p`
    color: ${COLORS.blueDarker};
    font-size: 13px;
    font-weight: 400;
    margin: 8px 0 24px 0;
    line-height: 150%;
`;

// Small Item

export const SmallItem = styled.div`
    display: flex;
    flex-direction: column;
`;

export const IconContaine = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
`;
