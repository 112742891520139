import ElasticCarousel from 'react-elastic-carousel';
import styled from 'styled-components';
import { SCREENS } from '@constants/screens';

// Carousel

export const CarouselContainer = styled.div`
    ${SCREENS.tabletAndUpper} {
        width: 304px;
        height: 280px;
    }

    ${SCREENS.desktopAverageAndUpper} {
        width: 544px;
        height: 280px;
    }

    ${SCREENS.desktopLargeAndUpper} {
        width: 624px;
        height: 616px;
    }

    margin-left: 96px;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
`;

export const Carousel = styled(ElasticCarousel)`
    .rec-carousel {
        // override ElasticCarousel

        ${SCREENS.tabletAndUpper} {
            height: 268px !important;
        }

        ${SCREENS.desktopAverageAndUpper} {
            height: 415px !important;
        }

        ${SCREENS.desktopLargeAndUpper} {
            height: 607px !important;
        }

        height: 368px !important;
        position: relative;

        button {
            background: none;
            border: none;
            z-index: 999;
            width: 28px;
            height: 40px;
            outline: none;
            cursor: pointer;
        }
    }
`;
