import styled, { css } from 'styled-components';
import AppBar from '@mui/material/AppBar';
import MaterialToolbar from '@mui/material/Toolbar';

import { COLORS } from '@constants/colors';
import { SCREENS } from '@constants/screens';
import { DownloadApple } from '@assets/svg/DownloadApple';
import { DownloadGoogle } from '@assets/svg/DownloadGoogle';

export const DownloadAppBar = styled(AppBar)`
    box-shadow: none;
    display: flex;
    justify-content: center;
`;

export const Toolbar = styled(MaterialToolbar)`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 10px 0;
    background-color: ${COLORS.pink};
    color: ${COLORS.white};

    ${SCREENS.tabletAndLower} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const Punchline = styled.div`
    color: inherit;
    margin-right: 20px;
    display: flex;
    text-align: end;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 88px;

    ${SCREENS.desktopMediumAndLower} {
        padding-left: 15px;
    }

    ${SCREENS.tabletAndLower} {
        flex-direction: column;
        align-self: stretch;
        margin: 0 20px 5px 0;
        text-align: center;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    color: inherit;
`;

const Span = styled.span`
    font-size: 15px;
    color: inherit;
    display: flex;

    ${SCREENS.mobileAndLower} {
        font-size: 13px;
    }
`;

export const NormalSpan = styled(Span)`
    font-weight: 400;
`;
export const MediumSpan = styled(Span)`
    font-weight: 500;
`;
export const BoldSpan = styled(Span)`
    font-weight: 700;
`;

export const Actions = styled.div`
    color: inherit;
    display: flex;
    flex: 2;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;

    ${SCREENS.desktopAverageAndLower} {
        flex: 0;
        justify-content: center;
    }

    ${SCREENS.tabletAndLower} {
        flex-direction: column;
    }
`;

export const ActionsInner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const AppButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const DownloadIcon = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    align-self: stretch;
    height: 100%;
`;

export const Google = styled(DownloadGoogle)`
    ${DownloadIcon};
    margin: -10px 16px -10px 0;
`;

export const Apple = styled(DownloadApple)`
    ${DownloadIcon};
`;

export const Text = styled.span`
    color: ${COLORS.white};
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
    flex: 2;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 48px;

    ${SCREENS.desktopAverageAndLower} {
        display: none;
    }

    ${SCREENS.desktopMediumAndLower} {
        font-size: 11px;
        margin-left: 20px;
        line-height: 20px;
    }
`;

export const CrossContainer = styled.div`
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;

    ${SCREENS.desktopAverageAndLower} {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        justify-content: flex-start;
        align-items: flex-start;
        padding-right: 15px;
        padding-top: 15px;
        padding-left: 0;
    }
`;
