import React, { useContext } from 'react';

import Emoji from '@components/Emoji/Emoji';
import { t } from '@locales/translation';
import { Cross } from '@assets/svg/Cross';
import useWindow from 'hooks/useWindow';
import { SIZES } from '@constants/sizes';
import { COLORS } from '@constants/colors';
import { CookieStoreContext } from '@stores/CookieStoreContext';

import * as S from './styled';

const DownloadHeader = (): JSX.Element | null => {
    return null;

    const { windowSize } = useWindow();

    const { mobileFirstBarCookie, addMobileFirstBarCookie } = useContext(CookieStoreContext);

    const onClickCross = () => {
        addMobileFirstBarCookie(false, 3600);
    };

    if (mobileFirstBarCookie) {
        return (
            <S.Toolbar>
                <S.Punchline>
                    <S.Row>
                        <S.NormalSpan>{t('header.download')}&nbsp;</S.NormalSpan>
                        <S.BoldSpan>Santé</S.BoldSpan>
                        <S.MediumSpan>Académie</S.MediumSpan>
                    </S.Row>
                    <S.NormalSpan>&nbsp;{t('header.smartphone')}&nbsp;</S.NormalSpan>
                    <Emoji
                        label={t('emoji.fingerRight')}
                        symbol={windowSize.width >= SIZES.tablet ? '👉' : '👇'}
                        style={{ fontSize: '20px' }}
                    />
                </S.Punchline>
                <S.Actions>
                    <S.ActionsInner>
                        <S.AppButtons>
                            <S.Google />
                            <S.Apple />
                        </S.AppButtons>
                        {windowSize.width >= SIZES.tablet ? <S.Text>{t('header.explanation')}</S.Text> : null}
                    </S.ActionsInner>

                    <S.CrossContainer onClick={onClickCross}>
                        <Cross fill={COLORS.white} />
                    </S.CrossContainer>
                </S.Actions>
            </S.Toolbar>
        );
    }
    return null;
};

export default DownloadHeader;
