import React from 'react';
import { CSSProperties } from 'styled-components';

import * as S from './styled';

type ITitleProps = {
    text: string;
    style?: CSSProperties;
};

const HomeTitle = ({ text, style }: ITitleProps): JSX.Element => (
    <S.Container style={style}>
        <S.Title>{text}</S.Title>
        <S.Underline />
    </S.Container>
);

export default HomeTitle;
