import React, { useContext, useState, useEffect } from 'react';

import {
    ContentStudioApiContext,
    IRegistrationShort,
    filterCurrentRegistrationsShort,
    filterPastRegistrationsShort,
    sortRegistrationsShortByStartedAd,
} from '@santeacademie/contentstudio-api-client';

import { DetailedCourseCard, CardsList } from '@components/Cards';
import HomeTitle from '@components/Text/HomeTitle/HomeTitle';
import HomeHeader from '@components/Header/HomeHeader/HomeHeader';
import { t } from '@locales/translation';
import BackgroundDraw from '@assets/images/BackgroundDraw.svg';
import Carousel from '@components/Carousel/Carousel';
import DownloadHeader from '@components/Header/DownloadHeader/DownloadHeader';
import { LoadingScreen } from '@components/Loading/LoadingScreen';
import { LEARNER_REGISTRATIONS } from '@constants/localStorage';
import { useLocalStorage } from '@hooks/useLocalStorage';

import * as S from './styled';

const Home = (): JSX.Element => {
    const { getLearnerRegistrations } = useContext(ContentStudioApiContext);
    const [learnerRegistrations, setLearnerRegistrations] = useState<IRegistrationShort[]>();

    const [, setLearnerRegistrationsLocalStorage] = useLocalStorage<string>(LEARNER_REGISTRATIONS, '');

    useEffect(() => {
        (async function fetchLearnerRegistrations() {
            try {
                const responseLearnerRegistrations: IRegistrationShort[] = await getLearnerRegistrations({});
                setLearnerRegistrationsLocalStorage(responseLearnerRegistrations.map((registration) => registration.course.code).join(','))
                setLearnerRegistrations(responseLearnerRegistrations);
            } catch (e) {
                console.error(e)
            }
        })();
    }, []);

    if (!learnerRegistrations) {
        // TODO : add a placeholder
        return <LoadingScreen />;
    }

    const pastRegistrations = filterPastRegistrationsShort(learnerRegistrations);
    const currentRegistrations = sortRegistrationsShortByStartedAd(
        filterCurrentRegistrationsShort(learnerRegistrations),
    );

    return (
        <>
            <S.BackgroundDraw>
                <BackgroundDraw />
            </S.BackgroundDraw>
            <HomeHeader />
            <DownloadHeader />
            <S.Container>
                <HomeTitle text={t('page.home.title.resumeRegistration')} />
                <S.OngoingContainer>
                    <DetailedCourseCard registration={currentRegistrations[0]} />
                    <Carousel />
                </S.OngoingContainer>

                {currentRegistrations.length > 0 && (
                    <>
                        <HomeTitle text={t('page.home.title.inProgressRegistrations')} />
                        <CardsList registrations={currentRegistrations} />
                    </>
                )}
                {/* <HomeTitle text={t("page.home.title.upcomingRegistrations")} />
        <CardsList courses={TEMP_FUTURE_COURSES_LIST} type="course" /> */}
                {pastRegistrations.length > 0 && (
                    <>
                        <HomeTitle text={t('page.home.title.pastRegistrations')} />
                        <CardsList registrations={pastRegistrations} />
                    </>
                )}
            </S.Container>
        </>
    );
};

export default Home;
