import { useState } from 'react';

/**
 * Custom hook to manage local storage
 * @param key The key under which the value is stored in local storage
 * @param initialValue The initial value to use if there is no value in local storage
 * @returns An array containing the stored value and a function to update it
 */
function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T | ((val: T) => T)) => void] {
  const isClient = typeof window !== 'undefined';

  const [storedValue, setStoredValue] = useState<T>(() => {
    if (!isClient) {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error('Error reading local storage', error);
      return initialValue;
    }
  });

  /**
   * Function to update the stored value in local storage
   * @param value The new value or a function that returns the new value
   */
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);

      if (isClient) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.error('Error setting local storage', error);
    }
  };

  return [storedValue, setValue];
}

export { useLocalStorage };
