import React from 'react';
import styled from 'styled-components';

import { SanteAcademieLogo } from '@assets/svg/SanteAcademieLogo';

import * as S from '../styled';
import UserMenu from '../UserMenu/UserMenu';

const ImageContainer = styled.div`
    flex-grow: 1;
`;

const Header = (): JSX.Element => (
    <S.StyledAppBar data-testid='home-header'>
        <S.StyledToolbar>
            <ImageContainer>
                <SanteAcademieLogo />
            </ImageContainer>
            <UserMenu />
        </S.StyledToolbar>
    </S.StyledAppBar>
);

export default Header;
