import styled from 'styled-components';
import { SCREENS } from '@constants/screens';

export const Container = styled.div`
    max-width: 100%;
    padding: 0 0 88px 0;

    ${SCREENS.tabletAndLower} {
        padding: 0 0 28px 0;
    }
`;

export const CoursesContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-right: 20px;

    &::after {
        content: '';
        border-right: 20px solid transparent;
    }
`;

export const BackgroundDraw = styled.div`
    position: fixed;
    left: 52%;
    bottom: 20%;
    z-index: -1;
`;

export const OngoingContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 88px;

    ${SCREENS.tabletAndLower} {
        margin: 0;
        padding: 0 10px;
    }
`;
