import { COLORS } from '@constants/colors';
import { SCREENS } from '@constants/screens';
import styled from 'styled-components';

// Title

export const Container = styled.div`
    ${SCREENS.tabletAndLower} {
        margin: 28px 22px 10px 22px;
    }
    margin: 48px 88px 12px 88px;
`;

export const Title = styled.h1`
    color: ${COLORS.blueDarker};
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
`;

export const Underline = styled.div`
    background-color: ${COLORS.turquoise};
    width: 52px;
    height: 8px;
    border-radius: 20px;
    z-index: -1;
    bottom: 9px;
    position: relative;
    opacity: 0.6;
`;
