// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useRef } from 'react';
import ReactElasticCarousel from 'react-elastic-carousel';

import { t } from '@locales/translation';
import useWindow from '@hooks/useWindow';
import { SIZES } from '@constants/sizes';

import * as S from './styled';
import Item, { ICarouselItemProps } from './Item/Item';
// import Arrow from './Arrow/Arrow';
// import Pagination from './Pagination/Pagination';

const items: ICarouselItemProps[] = [
    {
        image: 'online',
        icon: 'university',
        title: t('page.home.carousel.online.title'),
        content: t('page.home.carousel.online.content', { phoneLink: '02 22 44 42 11' }),
    },
    // {
    // image: 'trainer',
    // icon: 'discussion',
    // title: t('page.home.carousel.calendar.title'),
    // content: t('page.home.carousel.calendar.content'),
    // },
    // {
    // image: 'free',
    // icon: 'money',
    // title: t('page.home.carousel.indemnity.title'),
    // content: t('page.home.carousel.indemnity.content'),
    // },
];

// const courselPlaySpeed = 5000;
// let resetTimeout: NodeJS.Timeout;

// interface ICarouselEnd {
// index: number;
// }

const Carousel = (): JSX.Element | null => {
    const { windowSize } = useWindow();
    const carouselRef = useRef<ReactElasticCarousel>(null);

    if (windowSize.width <= SIZES.tablet) {
        return null;
    }

    // const onCarouselEnd = ({index}: ICarouselEnd) => {
    // clearTimeout(resetTimeout);
    // if (index + 1 === items.length) {
    // resetTimeout = setTimeout(() => {
    // if (carouselRef.current) {
    // carouselRef.current.goTo(0);
    // }
    // }, courselPlaySpeed);
    // }
    // };

    // const onChange = () => {
    // clearTimeout(resetTimeout);
    // };

    return (
        <S.CarouselContainer>
            <S.Carousel
                ref={carouselRef}
                itemsToShow={1}
                isRTL={false}
                // renderArrow={Arrow}
                showArrows={false}
                // enableAutoPlay
                // autoPlaySpeed={courselPlaySpeed}
                // renderPagination={Pagination}
                pagination={false}
                // onNextEnd={onCarouselEnd}
                // onChange={onChange}
                enableSwipe={false}
                enableMouseSwipe={false}
            >
                {items.map((item, index) => (
                    <Item
                        image={item.image}
                        icon={item.icon}
                        title={item.title}
                        content={item.content}
                        key={String(index)}
                    />
                ))}
            </S.Carousel>
        </S.CarouselContainer>
    );
};

export default Carousel;
