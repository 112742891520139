import React, { useEffect, useContext } from 'react';
import { HeadContext } from '@stores/HeadContext';

import Home from 'screens/Home/home';
import Layout from '@components/Layout/Layout';

const Index = (): JSX.Element => {
    const { setPageTitle } = useContext(HeadContext);

    useEffect(() => {
        setPageTitle('Santé Académie - Formations');
    }, []);

    return (
        <Layout>
            <Home />
        </Layout>
    );
};

export default Index;
