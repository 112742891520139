import React from 'react';
import nl2br from 'react-nl2br';

// import imageOnline from '@assets/images/alpaca-online.png';
// import imageTrainer from '@assets/images/alpaca-trainer.png';
// import imageFree from '@assets/images/alpaca-course_is_free.png';
import useWindow from 'hooks/useWindow';
import { SIZES } from '@constants/sizes';
// import iconDiscussion from '@assets/icons/carousel/discussion.png';
// import iconMoney from '@assets/icons/carousel/money.png';
// import iconUniversity from '@assets/icons/carousel/university.png';

import * as S from './styled';

export type ICarouselItemProps = {
    image: 'online' | 'trainer' | 'free';
    icon: 'discussion' | 'money' | 'university';
    title: string;
    content: string;
};

// const images: { [key: string]: string } = {
//     online: imageOnline,
//     trainer: imageTrainer,
//     free: imageFree,
// };

// const icons: { [key: string]: string } = {
//     discussion: iconDiscussion,
//     money: iconMoney,
//     university: iconUniversity,
// };

const LargeItem = ({ title, content }: ICarouselItemProps): JSX.Element => {
    const { windowSize } = useWindow();
    if (windowSize.width >= SIZES.desktopMedium) {
        return (
            <S.LargeItem>
                {/*<S.ImageContainer>*/}
                {/*    <img src={images[image]} alt={image} style={{ width: '100%', height: '100%' }} />*/}
                {/*</S.ImageContainer>*/}
                <div>
                    <S.ItemTitleContainer>
                        {/*<img src={icons[icon]} alt={icon} style={{ width: 42, height: 38 }} />*/}
                        <S.ItemTitle>{title}</S.ItemTitle>
                    </S.ItemTitleContainer>
                    <S.Text>{nl2br(content)}</S.Text>
                </div>
            </S.LargeItem>
        );
    }
    return (
        <S.SmallItem>
            {/*<S.IconContaine>*/}
            {/*    <img src={icons[icon]} alt={icon} style={{ width: 56, height: 56 }} />*/}
            {/*</S.IconContaine>*/}
            <S.ItemTitle>{title}</S.ItemTitle>
            <S.Text>{nl2br(content)}</S.Text>
        </S.SmallItem>
    );
};

export default LargeItem;
